import { ledgerAccountMap } from '@/api/ledgerAccount/ledgerAccount.model';
import { fiscalPersonTypeMap, afipConditionTypeMap } from '@/api/types';
import { MainButtonVariant } from '@/components/shared/AlertDialog';
import { LEDGER_ACCOUNT_TABS } from '@/pages/LedgerAccounts/utils/LedgerAccountUtils';
import { z } from 'zod';

const enum HttpCode {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

const GLOBAL_ERROR_MESSAGE = 'Lo sentimos, ha ocurrido un error. Intentalo nuevamente.';

const EMAIL_FORMAT_ERROR_MESSAGE = 'Formato de email inválido';
const EMAIL_REQUIRED_ERROR_MESSAGE = 'El email es obligatorio';
const NAME_REQUIRED_ERROR_MESSAGE = 'El nombre es obligatorio';
const ACCOUNTING_SUM_ERROR_MESSAGE = 'La suma de los montos del debe y haber deben ser iguales';
const ACCOUNTING_MAX_VALUE_ERROR_MESSAGE =
  'La suma de los montos debe coincidir con el total de la donación';
const PAYMENT_MAX_VALUE_ERROR_MESSAGE = 'No puede superar el monto total de la factura';
const UNSAVED_CHANGES_ALERT_DIALOG_CONFIG = {
  title: `¿Estás seguro que quieres continuar sin guardar los cambios? `,
  description: `Todos los cambios sin guardar se perderán. `,
  open: true,
  cancelLabel: 'Volver',
  mainButtonVariant: 'primary' as MainButtonVariant,
};
const EXPENSE_WITHOUT_AMOUNT_ERROR_MESSAGE = 'No pueden existir gastos con monto 0';
const ENTRY_WITHOUT_AMOUNT_ERROR_MESSAGE = 'No pueden existir movimientos con monto 0';

const MOBILE_BREAKPOINT = 1024;

const SONNER_SUCCESS_OPTIONS = {
  dismissible: true,
  closeButton: true,
};

const SONNER_ERROR_OPTIONS = {
  dismissible: true,
  closeButton: false,
};

const DEFAULT_PAGINATION_PAGE_COUNT = 1;
const DEFAULT_PAGINATION_PAGE_INDEX = 0;
const DEFAULT_PAGINATION_PAGE_SIZE = 10;
const DEFAULT_PAGINATION_SORT = 'createdAt';
const DEFAULT_PAGINATION_DIR = 'desc';
const DEFAULT_PAGINATION_SIZE_OPTIONS = [10, 20, 30, 40, 50, 100];
const DEFAULT_PAGINATION_PAGE_SEARCH = '';
const DEFAULT_INVOICE_STATE_OPTION = '';
const DEFAULT_PAYMENT_STATE_OPTION = '';
const DEFAULT_STATE_OPTION = '';
const DEfAULT_SUPPLIER_OPTION = '';
const DEFAULT_TERRITORY_OPTION = '';
const DEFAULT_PROJECT_OPTION = '';
const DEFAULT_COST_CENTER_OPTION = '';
const DEFAULT_DETAIL_OPTION = '';

const DEFAULT_DENY_ALERT_DIALOG = {
  title: '',
  description: '',
  open: false,
  onDismiss: () => {},
};

const DEFAULT_NAME_SCHEMA = z.string().min(1, 'El nombre es obligatorio');
const DEFAULT_LAST_NAME_SCHEMA = z.string().min(1, 'El apellido es obligatorio');
const DEFAULT_CBU_SCHEMA = z.string().optional().nullable();
const DEFAULT_EMAIL_SCHEMA = z.string().min(1, { message: 'El mail es obligatorio' }).email({
  message: EMAIL_FORMAT_ERROR_MESSAGE,
});
const DEFAULT_OPTIONAL_EMAIL_SCHEMA = z
  .string()
  .email({ message: EMAIL_FORMAT_ERROR_MESSAGE })
  .optional();
const DEFAULT_CODE_SCHEMA = z
  .string({
    required_error: 'El código es obligatorio',
    invalid_type_error: 'El código debe ser de tipo string',
  })
  .min(2, { message: 'El código es obligatorio y debe contener al menos 2 caracteres' });
const DEFAULT_ACCOUNT_NUMBER_SCHEMA = z
  .string({
    required_error: 'El número de cuenta es obligatorio',
    invalid_type_error: 'El número de cuenta debe ser de tipo string',
  })
  .min(1, { message: 'El número de cuenta es obligatorio' });

const FISCAL_PERSON_TYPE_OPTIONS = Object.entries(fiscalPersonTypeMap).map(([value, label]) => {
  return {
    value,
    label,
  };
});

const AFIP_CONDITION_TYPE_OPTIONS = Object.entries(afipConditionTypeMap).map(([value, label]) => {
  return {
    value,
    label,
  };
});

const LEDGER_ACCOUNT_TYPE_OPTIONS = Object.entries(ledgerAccountMap).map(([value, label]) => {
  return {
    value,
    label,
  };
});

const YEAR_OPTIONS = Array.from({ length: 10 }, (_, i) => {
  return {
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  };
});

const CURRENCY_OPTIONS = [
  { name: 'USD', value: 'USD', symbol: 'US$' },
  { name: 'ARS', value: 'ARS', symbol: 'AR$' },
  // { name: 'EUR', value: 'EUR', symbol: '€' },
] as const;

const DEFAULT_ENTRANCE_ANIMATION_DELAY = 300; // miliseconds

const ANIMATION_QUERY_PARAM = 'useAnimation';

const DETAIL_DISABLED_BUTTON_CLASS =
  'disabled:cursor-not-allowed disabled:bg-slate-100 disabled:opacity-1';

const DATES_OPTIONS = [
  { name: 'Fecha de Vencimiento', value: 'dueDate' },
  { name: 'Fecha de Comprobante', value: 'receiptDate' },
  { name: 'Fecha Contable', value: 'accountingDate' },
  { name: 'Fecha de Factura Pagada', value: 'paidDate' },
  { name: 'Fecha de Pago', value: 'paymentDate' },
];

const DEFAULT_LEDGERACCOUNT_TAB = LEDGER_ACCOUNT_TABS[3];

export {
  HttpCode,
  GLOBAL_ERROR_MESSAGE,
  EMAIL_FORMAT_ERROR_MESSAGE,
  EMAIL_REQUIRED_ERROR_MESSAGE,
  NAME_REQUIRED_ERROR_MESSAGE,
  ACCOUNTING_SUM_ERROR_MESSAGE,
  ENTRY_WITHOUT_AMOUNT_ERROR_MESSAGE,
  EXPENSE_WITHOUT_AMOUNT_ERROR_MESSAGE,
  MOBILE_BREAKPOINT,
  SONNER_SUCCESS_OPTIONS,
  SONNER_ERROR_OPTIONS,
  DATES_OPTIONS,
  DEFAULT_PAGINATION_PAGE_COUNT,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_PAGE_SEARCH,
  DEFAULT_INVOICE_STATE_OPTION,
  DEFAULT_PAYMENT_STATE_OPTION,
  DEFAULT_STATE_OPTION,
  DEFAULT_PAGINATION_SIZE_OPTIONS,
  DEfAULT_SUPPLIER_OPTION,
  DEFAULT_TERRITORY_OPTION,
  DEFAULT_PROJECT_OPTION,
  DEFAULT_COST_CENTER_OPTION,
  DEFAULT_DETAIL_OPTION,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_PAGINATION_DIR,
  DEFAULT_DENY_ALERT_DIALOG,
  DEFAULT_NAME_SCHEMA,
  DEFAULT_EMAIL_SCHEMA,
  DEFAULT_OPTIONAL_EMAIL_SCHEMA,
  DEFAULT_CBU_SCHEMA,
  DEFAULT_CODE_SCHEMA,
  FISCAL_PERSON_TYPE_OPTIONS,
  AFIP_CONDITION_TYPE_OPTIONS,
  LEDGER_ACCOUNT_TYPE_OPTIONS,
  YEAR_OPTIONS,
  CURRENCY_OPTIONS,
  DEFAULT_ENTRANCE_ANIMATION_DELAY,
  ANIMATION_QUERY_PARAM,
  DETAIL_DISABLED_BUTTON_CLASS,
  ACCOUNTING_MAX_VALUE_ERROR_MESSAGE,
  PAYMENT_MAX_VALUE_ERROR_MESSAGE,
  DEFAULT_LAST_NAME_SCHEMA,
  DEFAULT_ACCOUNT_NUMBER_SCHEMA,
  UNSAVED_CHANGES_ALERT_DIALOG_CONFIG,
  DEFAULT_LEDGERACCOUNT_TAB,
};
